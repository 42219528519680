import { reducePlace } from "~/bin/place/helpers";
import { ApiKey, fetchApi, FetchApiOrigin } from "~/common/API/baseApi";
import { errorMessage } from "~/common/feedback";
import { formatDate } from "~/common/functions";
import type {
  ExistingPlace,
  PlaceStat,
  PlaceStatReduced,
} from "~/interfaces/publisher";
import type { PlacePostResponse } from "~/interfaces/publisher/place";

const formatDateToStatParam = (ISODate: string) =>
  ISODate.split("T").join(" ").slice(0, 19);

export default class PlaceService {
  static getPlace = async (placeId: number | null | undefined | string) => {
    if (!placeId) {
      createError("Не указан id группы");
      errorMessage("Не указан id группы");
      return null;
    }
    const place = (await fetchApi({
      origin: FetchApiOrigin.LIGHT_API_ORIGIN,
      endpoint: `/place/telegram/get`,
      params: {
        id: placeId,
      },
    })) as ExistingPlace;

    return place;
  };

  static getPlacePosts = ({
    id,
    page,
  }: {
    id: string | number;
    page: string | number;
  }) =>
    fetchApi({
      origin: FetchApiOrigin.DATA_API_ORIGIN,
      endpoint: "/group-telegram/get-posts",
      params: {
        id,
        page,
        order: "desc",
        "page-size": 10,
      },
    }) as Promise<PlacePostResponse>;

  static getPlaceStat = async (placeId: number) => {
    const date = new Date();
    date.setHours(date.getHours() + 3);
    const dateTimeTo = formatDateToStatParam(date.toISOString());
    date.setFullYear(date.getFullYear() - 1);
    const dateTimeFrom = formatDateToStatParam(date.toISOString());

    const stat = (await fetchApi({
      origin: FetchApiOrigin.DATA_API_ORIGIN,
      endpoint: "/statistic/group-statistic-report",
      params: {
        groupId: placeId,
        social: "telegram",
        dateTimeFrom,
        dateTimeTo,
      },
    })) as PlaceStat[];

    const reduced = stat.map(
      ({
        postsStatistic: { countLast24h: posts },
        subscribersStatistic: { count: subscribers },
        viewsStatistic: { countLast24h: views },
        dateTime: { date },
      }) => ({
        posts: posts ?? 0,
        subscribers: subscribers ?? 0,
        views: views ?? 0,
        date: formatDate(date),
        er: (views ?? 0) / (subscribers ?? 1),
      }),
    );

    return reduced as unknown as PlaceStatReduced[];
  };

  static getOtherPlaces = async (userId: number) => {
    const places = (await fetchApi({
      origin: FetchApiOrigin.LIGHT_API_ORIGIN,
      endpoint: "/place/telegram/search",
      params: {
        userId,
      },
    })) as ExistingPlace[];

    return places;
  };

  static getPlaceStatistic = async (placeId: number) => {
    const stats = (await fetchApi({
      origin: FetchApiOrigin.LIGHT_API_ORIGIN,
      endpoint: "/place/telegram/statistic-chart",
      params: {
        id: placeId,
      },
    })) as PlaceStatReduced[];

    return stats;
  };

  static getPlaces = async (filter: {
    search?: string;
    category?: string[];
    countries?: string[];
    subscribersTo?: number;
    subscribersFrom?: number;
    ageFrom?: number;
    ageTo?: number;
  }) => {
    const places = (await fetchApi({
      origin: FetchApiOrigin.LIGHT_API_ORIGIN,
      endpoint: "/place/telegram/search",
      params: {
        isActive: true,
        ...filter,
      },
    })) as ExistingPlace[];

    return places;
  };
}
